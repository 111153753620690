import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import axios from "axios";
import "./index.css";
import dataFeeds from "./output.json";

// Mapping of networks to their chainId
const NETWORK_CHAIN_ID = {
  bnb: 56,
  arbitrum: 42161,
  optimism: 10,
};

function PriceUpdatesTable({ data }) {
  return (
    <div className="my-4 p-4 border rounded">
      <h3 className="font-semibold text-lg mb-2 text-center">
        Recent Price Updates Indexed
      </h3>
      <table className="border-collapse">
        <thead>
          <tr>
            <th className="border px-4 py-2">Updated At</th>
            <th className="border px-4 py-2">Price</th>
          </tr>
        </thead>
        <tbody>
          {data.map((priceData, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">
                {new Date(priceData.updatedAt).toLocaleString("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                })}
              </td>
              <td className="border px-4 py-2">
                ${priceData.chainlinkPrice.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function EducationalSection() {
  return (
    <div>
      <h3 class="font-semibold text-lg mb-2">Heartbeat</h3>
      <ul>
        <li>
          Heartbeat of a price feed is the maximum amount of time that is
          allowed to pass after a price update before it is updated again. For
          example, a one-minute heartbeat would trigger an update if at least
          one minute has passed since the last on-chain update.
        </li>

        <li>
          Understanding the heartbeat is essential because it represents the
          expected frequency at which a price feed contract updates its data.
        </li>
        <li>
          Heartbeat of a price feed should inform the mechanism design of the
          smart contract, more specifically how often it executes its logic. For
          example, if the smart contract logic requires a price feed update
          faster than the heartbeat of the price feed on average, it could lead
          to redundant smart contract logic execution using stale prices.
        </li>
        <li>
          Heartbeat is also important from a security perspective, as it can be
          used as a proxy for how long the price has been stale for, and
          therefore how much time a malicious actor has to perform a malicious
          action before the price is updated.
        </li>
        <li>
          Actual frequency of price feed updates is often quicker than the
          expected heartbeat, as price updates can also be triggered by the
          deviation threshold. This can also be adversely impacted by the level
          of network congestion. The smart contracts should be engineered to
          halt gracefully should the actual price update frequency be slower
          than the expected heartbeat to prevent any impacts to the users.
        </li>
      </ul>
      <br></br>
      <h3 class="font-semibold text-lg mb-2">Deviation Threshold</h3>
      <ul>
        <li>
          Deviation threshold is the threshold of price movement that will
          trigger a price update. For example, a 0.05% deviation threshold would
          trigger an oracle update if the price of an asset increases or
          decreases by 0.05% since the last on-chain update.
        </li>
        <li>
          Similarly, comprehending the deviation threshold is vital for
          developers working with price feeds. It acts as a security mechanism
          to protect against price manipulation or inaccuracies. By considering
          the deviation threshold, developers can implement robust error
          handling mechanisms and ensure the integrity of their contract's
          functionality.
        </li>
        <li>
          Usually, the observed price differences between updates are higher
          than the deviation threshold of the price feed, as it is crossing this
          threshold that often results in a price update.
        </li>
        <li>
          For any smart contract that updates financial values depending on the
          size of the price movement, developers need to be aware of the
          distribution of the size of the price changes. This point is
          especially important for smart contracts that apply a leverage
          multiple to the price changes. The smart contracts should ideally
          handle very large price changes gracefully.
        </li>
      </ul>
    </div>
  );
}

function App() {
  const networks = Object.keys(dataFeeds);
  const defaultNetwork = networks[0];
  const defaultFeed = Object.values(dataFeeds[defaultNetwork]).find(
    (feed) => feed.description === "ETH / USD"
  );

  if (!defaultFeed) {
    console.error(`No default feed found for network ${defaultNetwork}`);
  }

  const [selectedFeed, setSelectedFeed] = useState(defaultFeed || {});
  const [selectedNetwork, setSelectedNetwork] = useState(defaultNetwork);
  const [fetchLimit, setFetchLimit] = useState(100);
  const [hasuraData, setHasuraData] = useState([]);
  const [recentUpdates, setRecentUpdates] = useState([]);

  const [stats, setStats] = useState({
    priceMin: null,
    priceMax: null,
    avgPriceDiffPercentage: null,
    minPriceDiffPercentage: null,
    maxPriceDiffPercentage: null,
    medianPriceDiffPercentage: null,
    avgTimeDiff: null,
    minTimeDiff: null,
    maxTimeDiff: null,
    medianTimeDiff: null,
  });

  const fetchDataFromHasura = () => {
    if (!selectedFeed || !selectedFeed.aggregator || !selectedFeed.decimals) {
      console.log("Feed is not correctly selected");
      return;
    }

    // Here chainId is defined
    const chainId = NETWORK_CHAIN_ID[selectedNetwork];

    let priceQuery = `
    query MyQuery {
      price(order_by: {updatedAt: desc}, limit: ${fetchLimit}, where: {contractAddress: {_eq: "${selectedFeed.aggregator}"}, event_chain_id: {_eq: ${chainId}}}) {
        price
        updatedAt
      }
    }
  `;

    axios
      .post(
        "https://oracles.envio.work/v1/graphql",
        JSON.stringify({
          query: priceQuery,
          variables: {},
          operationName: "MyQuery",
        }),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        const reversedData = response.data.data.price.reverse();
        let previousDatum;
        let priceMin = Infinity;
        let priceMax = -Infinity;
        let minPriceDiffPercentage = Infinity;
        let maxPriceDiffPercentage = -Infinity;
        let maxTimeDiff = -Infinity;
        let minTimeDiff = -Infinity;
        let priceDiffs = [];
        let timeDiffs = [];

        reversedData.forEach((datum, index) => {
          datum.updatedAt = new Date(datum.updatedAt * 1000);
          datum.chainlinkPrice =
            datum.price / Math.pow(10, selectedFeed.decimals);
          const { chainlinkPrice, updatedAt } = datum;

          priceMin = Math.min(priceMin, chainlinkPrice);
          priceMax = Math.max(priceMax, chainlinkPrice);

          if (previousDatum) {
            const priceDiffPercentage = Math.abs(
              ((chainlinkPrice - previousDatum.chainlinkPrice) /
                previousDatum.chainlinkPrice) *
                100
            );
            maxPriceDiffPercentage = Math.max(
              maxPriceDiffPercentage,
              priceDiffPercentage
            );

            minPriceDiffPercentage = Math.min(
              maxPriceDiffPercentage,
              priceDiffPercentage
            );

            const timeDiff = (updatedAt - previousDatum.updatedAt) / 1000; // Convert to seconds
            maxTimeDiff = Math.max(maxTimeDiff, timeDiff);
            minTimeDiff = Math.min(maxTimeDiff, timeDiff);

            priceDiffs.push(priceDiffPercentage);
            timeDiffs.push(timeDiff);
          }

          previousDatum = datum;
        });

        // sort the arrays
        priceDiffs.sort((a, b) => a - b);
        timeDiffs.sort((a, b) => a - b);

        // Calculate average and median for price diff
        const avgPriceDiffPercentage =
          priceDiffs.reduce((a, b) => a + b, 0) / priceDiffs.length;
        let medianPriceDiffPercentage;
        if (priceDiffs.length % 2) {
          medianPriceDiffPercentage = priceDiffs[(priceDiffs.length - 1) / 2];
        } else {
          medianPriceDiffPercentage =
            (priceDiffs[priceDiffs.length / 2] +
              priceDiffs[priceDiffs.length / 2 - 1]) /
            2;
        }

        // Calculate average and median for time diff
        const avgTimeDiff =
          timeDiffs.reduce((a, b) => a + b, 0) / timeDiffs.length;
        let medianTimeDiff;
        if (timeDiffs.length % 2) {
          medianTimeDiff = timeDiffs[(timeDiffs.length - 1) / 2];
        } else {
          medianTimeDiff =
            (timeDiffs[timeDiffs.length / 2] +
              timeDiffs[timeDiffs.length / 2 - 1]) /
            2;
        }

        setHasuraData(reversedData);

        setStats({
          priceMin,
          priceMax,
          minPriceDiffPercentage,
          medianPriceDiffPercentage,
          avgPriceDiffPercentage,
          maxPriceDiffPercentage,
          minTimeDiff,
          medianTimeDiff,
          avgTimeDiff,
          maxTimeDiff,
        });
      })
      .catch((error) => {
        console.error(`Error fetching data: ${error}`);
      });
  };

  const handleLimitChange = (e) => {
    const value = e.target.value;
    setFetchLimit(Number(value)); // convert input to number
  };

  const handleFeedChange = (e) => {
    const feedDescription = e.target.value;
    const feed = Object.values(dataFeeds[selectedNetwork]).find(
      (feed) => feed.description === feedDescription
    );
    setSelectedFeed(feed);
  };

  const handleNetworkChange = (e) => {
    const network = e.target.value;
    setSelectedNetwork(network);

    // Update the selected feed to the default feed for the new network
    const feed = Object.values(dataFeeds[network]).find(
      (feed) => feed.description === "ETH / USD"
    );
    setSelectedFeed(feed);
  };

  const downloadDataAsCSV = () => {
    const limitedData = hasuraData.slice(0, 10000); // Limit the data to 10,000 points

    const csvHeading = "Updated At,Price\n";
    const csvData = limitedData
      .map(
        (datum) =>
          `${datum.updatedAt.toISOString()},${datum.chainlinkPrice.toFixed(2)}`
      )
      .join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${csvHeading}${csvData}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "price_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (selectedFeed) {
      fetchDataFromHasura();
      const interval = setInterval(() => {
        fetchDataFromHasura();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [selectedFeed, fetchLimit, selectedNetwork]); // Also re-fetch when selectedNetwork changes

  useEffect(() => {
    // Update the recentUpdates state with the 20 most recent updates
    const sortedData = hasuraData
      .slice(0, 20)
      .sort((a, b) => b.updatedAt - a.updatedAt);
    setRecentUpdates(sortedData);
  }, [hasuraData]);

  return (
    <div className= "flex flex-col items-center justify-center mx-auto px-4 sm:px-6 lg:px-8 bg-black text-white" style={{height: "100vh"}}>
      <h1 className="text-2xl font-bold">
        Chainlink Oracle Price Feed Dashboard
      </h1>
      <h2 className="text-xl font-medium">
        Indexed by Envio -  Index like a pro
      </h2>
      <h2 className="text-lg font-medium">
        {selectedFeed.description} {selectedNetwork} feed
      </h2>
      <div className="flex flex-row">
        <div className="w-1/6 flex flex-col">
          <label htmlFor="network" className="text-lg">
            Network
          </label>
          <select
            id="network"
            key={selectedNetwork ? selectedNetwork : ""}
            value={selectedNetwork || ""}
            onChange={handleNetworkChange}
            className="bg-gray-900 border p-1 m-y-1"
          >
            {networks.map((network) => (
              <option key={network} value={network}>
                {network}
              </option>
            ))}
          </select>
          <label htmlFor="feed" className="text-lg">
            Price Feed
          </label>
          <select
            id="feed"
            key={selectedFeed ? selectedFeed.description : ""}
            value={selectedFeed.description || ""}
            onChange={handleFeedChange}
            className="bg-gray-900 border p-1 m-y-1"
          >
            {Object.values(dataFeeds[selectedNetwork]).map((feed) => (
              <option key={feed.description} value={feed.description}>
                {feed.description}
              </option>
            ))}
          </select>
          <div className="flex items-center space-x-2">
            <label htmlFor="fetchLimit" className="text-lg">
              Number of prices to fetch
            </label>
            <select
              id="fetchLimit"
              value={fetchLimit}
              onChange={handleLimitChange}
              className="bg-gray-900 border p-1"
            >
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="10000">10000</option>
              <option value="50000">50000</option>
              <option value="100000">100000</option>
            </select>
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={downloadDataAsCSV}
          >
            Download Data
          </button>
        </div>
        <div className="w-5/6">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              // width={1500}
              // height={300}
              data={hasuraData}
              margin={{ top: 5, right: 20, bottom: 5, left: 80 }}
            >
              <Line
                type="monotone"
                dataKey="chainlinkPrice"
                stroke="#8884d8"
                isAnimationActive={false}
                connectNulls={true}
              />
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="updatedAt"
                scale="time"
                tickFormatter={(tick) => new Date(tick).toLocaleString()}
              />
              <YAxis
                domain={["dataMin", "dataMax"]}
                tickFormatter={(tick) => `$${tick.toFixed(2)}`}
              ></YAxis>
              <Tooltip />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="my-4 p-4 border rounded">
          <h3 className="font-semibold text-lg mb-2">Statistics</h3>
          <p>
            <b>Price ($)</b>
          </p>
          <p>Minimum: {stats.priceMin ? stats.priceMin.toFixed(2) : "N/A"}</p>
          <p>Maximum: {stats.priceMax ? stats.priceMax.toFixed(2) : "N/A"}</p>
          <p>
            <b>Price Difference (%)</b>
          </p>
          <p>
            Minimum:{" "}
            {stats.minPriceDiffPercentage
              ? stats.minPriceDiffPercentage.toFixed(4)
              : "N/A"}
            %
          </p>
          <p>
            Mean:{" "}
            {stats.avgPriceDiffPercentage
              ? stats.avgPriceDiffPercentage.toFixed(4)
              : "N/A"}
            %
          </p>
          <p>
            Median:{" "}
            {stats.medianPriceDiffPercentage
              ? stats.medianPriceDiffPercentage.toFixed(4)
              : "N/A"}
            %
          </p>
          <p>
            Maximum:{" "}
            {stats.maxPriceDiffPercentage
              ? stats.maxPriceDiffPercentage.toFixed(4)
              : "N/A"}
            %
          </p>
          <p>
            <b>Time Difference (s)</b>
          </p>
          <p>Minimum: {stats.minTimeDiff || "N/A"}</p>
          <p>
            Mean: {stats.avgTimeDiff ? stats.avgTimeDiff.toFixed(2) : "N/A"}
          </p>
          <p>
            Median:{" "}
            {stats.medianTimeDiff ? stats.medianTimeDiff.toFixed(2) : "N/A"}{" "}
          </p>
          <p>Maximum: {stats.maxTimeDiff || "N/A"}</p>
        </div>
        <div className="overflow-y-scroll max-h-[400px] mx-7">
          <PriceUpdatesTable data={recentUpdates} />
        </div>
        <div className="w-1/2 overflow-y-scroll max-h-[400px] my-4 p-4 border rounded">
          <EducationalSection />
        </div>
      </div>
    </div>
  );
}

export default App;
